import {
  API,
  API_PROJECT,
  API_TEMPLATE_DOWNLOAD,
  API_DOWNLOAD,
  API_UPLOAD_HISTORY,
  API_UPLOAD_URL,
  API_AUTH,
  API_MANUAL_UPLOAD,
  API_DOWNLOAD_FILE_URL
} from "../../api";
import * as ACTION from "../actionTypes";
import {
  getUserEmail,
  getFirebaseToken,
  clearAll
} from "../../helpers/utility";
import FileSaver from "file-saver";
import axios from "axios";

export function listProjects() {
  return dispatch => {
    dispatch(fetchListProjectBegin());
    return API.get(`${API_PROJECT}?email=${getUserEmail()}`, {}, {})
      .then(res => {
        dispatch(fetchListProjectSuccess(res.data?.data?.projects));
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
        dispatch(fetchListProjectError(err));
      });
  };
}

export const fetchListProjectBegin = () => ({
  type: ACTION.LIST_PROJECT_BEGIN
});

export const fetchListProjectSuccess = list => ({
  type: ACTION.LIST_PROJECT_SUCCESS,
  payload: list
});

export const fetchListProjectError = () => ({
  type: ACTION.LIST_PROJECT_ERROR,
  error: "Unable to load projects! please try after sometime."
});

export function templateDownload() {
  return dispatch => {
    dispatch(templateDownloadBegin());
    return API.get(
      `${API_TEMPLATE_DOWNLOAD}`,
      { responseType: "arraybuffer" },
      {}
    )
      .then(res => {
        var blob = new Blob([res.data], {
          type: "text/csv"
        });
        FileSaver.saveAs(blob, "seersignals-template.csv");
        dispatch(templateDownloadSuccess(res));
      })
      .catch(err => {
        dispatch(templateDownloadError(err));
      });
  };
}

export const templateDownloadBegin = () => ({
  type: ACTION.TEMPLATE_DOWNLOAD_BEGIN
});

export const templateDownloadSuccess = message => ({
  type: ACTION.TEMPLATE_DOWNLOAD_SUCCESS,
  payload: message
});

export const templateDownloadError = () => ({
  type: ACTION.TEMPLATE_DOWNLOAD_SUCCESS,
  error: "Unable to download template! please try after sometime."
});

export function download(schedule, project, callBack, callBackFn, successFn) {
  return dispatch => {
    dispatch(downloadBegin());
    return API.get(
      `${API_DOWNLOAD}?email=${getUserEmail()}&project_id=${project}&frequency=${schedule}`,
      { responseType: "arraybuffer" },
      {}
    )

      .then(res => {
        var blob = new Blob([res.data], {
          type: "text/csv"
        });
        FileSaver.saveAs(blob, "keyword.csv");
        dispatch(downloadSuccess(res));
        successFn && successFn();
      })
      .catch(err => {
        if (err?.response?.status === 406) {
          dispatch(downloadError("File will be send later via email"));
          callBack && callBack();
        } else {
          dispatch(
            downloadError(
              "Unable to download keywords! please try after sometime."
            )
          );
          callBackFn && callBackFn();
        }
      });
  };
}

export const downloadBegin = () => ({
  type: ACTION.DOWNLOAD_BEGIN
});

export const downloadSuccess = message => ({
  type: ACTION.DOWNLOAD_SUCCESS,
  payload: message
});

export const downloadError = error => ({
  type: ACTION.DOWNLOAD_ERROR,
  error: error
});

export function uploadHistory(projectId) {
  return dispatch => {
    dispatch(uploadHistoryBegin());
    return API.get(`${API_UPLOAD_HISTORY}?project_id=${projectId}`, {}, {})
      .then(res => {
        dispatch(uploadHistorySuccess(res.data?.data?.uploads));
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
        dispatch(uploadHistoryError(err));
      });
  };
}

export const uploadHistoryBegin = () => ({
  type: ACTION.UPLOAD_HISTORY_BEGIN
});

export const uploadHistorySuccess = history => ({
  type: ACTION.UPLOAD_HISTORY_SUCCESS,
  payload: history
});

export const uploadHistoryError = error => ({
  type: ACTION.UPLOAD_HISTORY_ERROR,
  error: error
});

export function getUrl(data, callBack) {
  return dispatch => {
    dispatch(getUrlBegin());
    return API.get(`${API_UPLOAD_URL}?email=${data.email}&file_name=${data.file_name}&project_id=${data.project_id}`, {}, {})
      .then(res => {
        dispatch(getUrlSuccess(res.data?.data));
        callBack && callBack();
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
        dispatch(getUrlError(err));
      });
  };
}

export const getUrlBegin = () => ({
  type: ACTION.UPLOAD_URL_BEGIN
});

export const getUrlSuccess = response => ({
  type: ACTION.UPLOAD_URL_SUCCESS,
  payload: response
});

export const getUrlError = () => ({
  type: ACTION.UPLOAD_URL_ERROR,
  error: "Unable to get the url! please try after sometime."
});

export function upload(file, url) {
  return dispatch => {
    dispatch(uploadBegin());
    return API.put(url, file, {})
      .then(res => {
        dispatch(uploadSuccess(res));
      })
      .catch(err => {
        dispatch(uploadError(err));
      });
  };
}

export const uploadBegin = () => ({
  type: ACTION.UPLOAD_BEGIN
});

export const uploadSuccess = response => ({
  type: ACTION.UPLOAD_SUCCESS,
  payload: response
});

export const uploadError = () => ({
  type: ACTION.UPLOAD_ERROR,
  error: "Unable to upload file! please try after sometime."
});

export function auth() {
  return dispatch => {
    dispatch(authBegin());
    return API.post(`${API_AUTH}`, { firebase_token: getFirebaseToken() }, {})
      .then(res => {
        let now = new Date().getTime();
        res.data.jupiter_token
          ? localStorage.setItem("access_token", res.data.jupiter_token)
          : clearAll();
        localStorage.setItem("setup_time", now);
        dispatch(listProjects());
        //dispatch(uploadHistory());
        dispatch(authSuccess(res));
      })
      .catch(err => {
        dispatch(authError("You are not authorized"));
      });
  };
}

export const authBegin = () => ({
  type: ACTION.AUTH_BEGIN
});

export const authSuccess = res => ({
  type: ACTION.AUTH_SUCCESS,
  payload: res
});

export const authError = message => ({
  type: ACTION.AUTH_ERROR,
  error: message
});

export function manualUpload(keywords) {
  return dispatch => {
    dispatch(manualUploadBegin());
    return API.post(`${API_MANUAL_UPLOAD}`, keywords, {})
      .then(res => {
        dispatch(manualUploadSuccess(res));
      })
      .catch(err => {
        dispatch(manualUploadError(err));
      });
  };
}
export function dispatchManualUploadSetFalse() {
  return dispatch => {
    dispatch(manualUploadSetFalse());
  }
}

export const manualUploadBegin = () => ({
  type: ACTION.MANUAL_UPLOAD_BEGIN
});

export const manualUploadSetFalse = () => ({
  type: ACTION.MANUAL_UPLOAD_SET_FALSE
});

export const manualUploadSuccess = list => ({
  type: ACTION.MANUAL_UPLOAD_SUCCESS,
  payload: list
});

export const manualUploadError = () => ({
  type: ACTION.MANUAL_UPLOAD_ERROR,
  error: "Unable to upload keywords! please try after sometime."
});

export const setManualUploadFormVisible = () => ({
  type: ACTION.SET_MANUAL_UPLOAD_FORM_VISIBLE
});

export const setBulkUploadFormVisible = () => ({
  type: ACTION.SET_BULK_UPLOAD_FORM_VISIBLE
});

export function bulkUpload(url, file) {
  return dispatch => {
    dispatch(bulkUploadBegin());
    return axios
      .put(url, file, {
        headers: {
          "Content-Type": "text/csv"
        }
      })
      .then(res => {
        dispatch(bulkUploadSuccess(res));
      })
      .catch(err => {
        dispatch(bulkUploadError(err));
      });
  };
}

export const bulkUploadBegin = () => ({
  type: ACTION.BULK_UPLOAD_BEGIN
});

export const bulkUploadSuccess = list => ({
  type: ACTION.BULK_UPLOAD_SUCCESS,
  payload: list
});

export const bulkUploadError = () => ({
  type: ACTION.BULK_UPLOAD_ERROR,
  error: "Unable to upload keywords! please try after sometime."
});

export function getDownloadFileUrl(url) {
  return dispatch => {
    dispatch(getDownloadFileBegin());
    const urlDetails = `?file_path=${url}`;
    return API.get((`${API_DOWNLOAD_FILE_URL}${urlDetails}`), {}, {})
      .then(res => {
        dispatch(getDownloadFileSuccess(res?.data?.data));
        
        //download as excel when clicked on the Anchor tag
        const link = document.createElement('a');
        link.href = res?.data?.data?.download_url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
        dispatch(getDownloadFileError(err));
      });
  }
}

export const getDownloadFileBegin = () => ({
  type: ACTION.FETCH_DOWNLOAD_FILE_BEGIN,
});

export const getDownloadFileSuccess = list => ({
  type: ACTION.FETCH_DOWNLOAD_FILE_SUCCESS,
  payload: list
});

export const getDownloadFileError = () => ({
  type: ACTION.FETCH_DOWNLOAD_FILE_ERROR,
});

